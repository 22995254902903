import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TimedValue from "./TimedValue";
import TimedValuePanel from "./TimedValuePanel";
import TimedValueChart from "./TimedValueChart";
import {
    calculateState,
    calculateUsage,
    loraDataRateToValue,
    calculateActive,
    groupBy, chooseAlarm, chooseDailyDose, chooseInterImpulseTime, truncateTimeToDay, round, pairwise, sum, sliding
} from "./common";
import { useLocation, useParams } from "react-router-dom";
import moment from "moment";
import { gwIdToName } from "./gwIdToName";
import RichTable from "./RichTable";
import TimedValueBarChart from "./TimedValueBarChart";

const useStyles = makeStyles(theme => ({
    container: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
    },
    panel: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        height: 144
    },
    chartPanel: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        height: 233,
    },
    divider: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    link: {
        color: theme.palette.text.primary,
        textDecoration: "none",
    },
    lastReadingsTable: {
        whiteSpace: 'nowrap',
        height: 233
    },
    lastAlarmsTable: {
        whiteSpace: 'nowrap',
        height: 233
    }
}));

export default function DeviceDashboard({ device, deviceStatuses, kiosk }) {
    const classes = useStyles();

    //const params = useParams();

    //const organizationId = params.organizationId;
    //const groupId = params.groupId;

    const readingStatuses = deviceStatuses.filter(status => status.isReading);
    //const alarmStatuses = deviceStatuses.filter(status => status.isAlarm);

    //const coldActive = calculateActive(device, "COLD");
    //const hotActive = calculateActive(device, "HOT");

    const usageColdRows = Object.entries(groupBy(sliding(readingStatuses, 2).filter(value => value.length > 0).map(statusPair => {
        let usage = statusPair.length > 1 ? calculateUsage(device, statusPair[1], statusPair[0], "COLD") : calculateUsage(device, statusPair[0], statusPair[0], "COLD");
        let time = statusPair.length > 1 ? statusPair[1].time : statusPair[0].time;
        return { time: truncateTimeToDay(time), value: usage.value == null || isNaN(usage.value) ? 0 : usage.value };
    }), "time")).map(a => {
        return new TimedValue(new Date(Date.parse(a[0])), sum(a[1].map(value => value.value)));
    }).sort((a, b) => b.time - a.time);

    const usageHotRows = Object.entries(groupBy(sliding(readingStatuses, 2).filter(value => value.length > 0).map(statusPair => {
        let usage = statusPair.length > 1 ? calculateUsage(device, statusPair[1], statusPair[0], "HOT") : calculateUsage(device, statusPair[0], statusPair[0], "HOT");
        let time = statusPair.length > 1 ? statusPair[1].time : statusPair[0].time;
        return { time: truncateTimeToDay(time), value: usage.value == null || isNaN(usage.value) ? 0 : usage.value };
    }), "time")).map(a => {
        return new TimedValue(new Date(Date.parse(a[0])), sum(a[1].map(value => value.value)));
    }).sort((a, b) => b.time - a.time);

    const usageHeatRows = Object.entries(groupBy(sliding(readingStatuses, 2).filter(value => value.length > 0).map(statusPair => {
        let usage = statusPair.length > 1 ? calculateUsage(device, statusPair[1], statusPair[0], "HEAT") : calculateUsage(device, statusPair[0], statusPair[0], "HEAT");
        let time = statusPair.length > 1 ? statusPair[1].time : statusPair[0].time;
        return { time: truncateTimeToDay(time), value: usage.value == null || isNaN(usage.value) ? 0 : usage.value };
    }), "time")).map(a => {
        return new TimedValue(new Date(Date.parse(a[0])), sum(a[1].map(value => value.value)));
    }).sort((a, b) => b.time - a.time);

    return (
        <Container maxWidth={false} className={classes.container}>
            <Grid container spacing={2}>
                <Grid item xs={6} md={4} lg={4}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12} lg={12}>
                            <Paper className={classes.panel}>
                                <TimedValuePanel title="Stan ZW"
                                    unit=" m3"
                                    color="black"
                                    timedValue={calculateState(device, readingStatuses[0], "COLD")} />
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <Paper className={classes.panel}>
                                <TimedValuePanel title="Zużycie ZW"
                                    unit=" m3"
                                    color="black"
                                    timedValue={calculateUsage(device, readingStatuses[readingStatuses.length - 1], readingStatuses[0], "COLD")} />
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6} md={4} lg={4}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12} lg={12}>
                            <Paper className={classes.panel}>
                                <TimedValuePanel title="Stan CW"
                                    unit=" m3"
                                    color="black"
                                    timedValue={calculateState(device, readingStatuses[0], "HOT")} />
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <Paper className={classes.panel}>
                                <TimedValuePanel title="Zużycie CW"
                                    unit=" m3"
                                    color="black"
                                    timedValue={calculateUsage(device, readingStatuses[readingStatuses.length - 1], readingStatuses[0], "HOT")} />
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6} md={4} lg={4}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12} lg={12}>
                            <Paper className={classes.panel}>
                                <TimedValuePanel title="Stan ciepła"
                                    unit=" GJ"
                                    color="black"
                                    timedValue={calculateState(device, readingStatuses[0], "HEAT")} />
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <Paper className={classes.panel}>
                                <TimedValuePanel title="Zużycie ciepła"
                                    unit=" GJ"
                                    color="black"
                                    timedValue={calculateUsage(device, readingStatuses[readingStatuses.length - 1], readingStatuses[0], "HEAT")} />
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} md={4} lg={4}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12} lg={12}>
                            <Paper className={classes.chartPanel}>
                                <TimedValueChart title="Stan ZW"
                                    label="m3"
                                    color="black"
                                    entries={readingStatuses.map(a => {
                                        return calculateState(device, a, "COLD");
                                    })} />
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <Paper className={classes.chartPanel}>
                                <TimedValueBarChart title="Zużycie dzienne ZW"
                                    label="m3"
                                    color="black"
                                    format="L LTS"
                                    entries={usageColdRows}
                                />
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12} lg={12}>
                            <Paper className={classes.chartPanel}>
                                <TimedValueChart title="Stan CW"
                                    label="m3"
                                    color="black"
                                    entries={readingStatuses.map(a => {
                                        return calculateState(device, a, "HOT");
                                    })} />
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <Paper className={classes.chartPanel}>
                                <TimedValueBarChart title="Zużycie dzienne CW"
                                    label="m3"
                                    color="black"
                                    format="L LTS"
                                    entries={usageHotRows}
                                />
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={4} lg={4}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12} lg={12}>
                            <Paper className={classes.chartPanel}>
                                <TimedValueChart title="Stan ciepła"
                                    label="GJ"
                                    color="black"
                                    entries={readingStatuses.map(a => {
                                        return calculateState(device, a, "HEAT");
                                    })} />
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <Paper className={classes.chartPanel}>
                                <TimedValueBarChart title="Zużycie dzienne ciepła"
                                    label="GJ"
                                    color="black"
                                    format="L LTS"
                                    entries={usageHeatRows}
                                />
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={6}>
                    <RichTable name="Ostatnie zdarzenia" columns={
                        [
                            { id: 'time', numeric: false, disablePadding: false, label: 'Czas' },
                            { id: 'type', numeric: false, disablePadding: false, label: 'Typ' },
                            { id: 'message', numeric: false, disablePadding: false, label: 'Wiadomość' },
                            { id: 'gw', numeric: false, disablePadding: false, label: 'Brama' }
                        ]
                    }
                        initialRowsById={new Map(deviceStatuses.filter(value => value.isAlarm || value.isCalibration).map((status, index) => {
                            const id = index;
                            return [id, {
                                time: moment(status.time).format("YYYY-MM-DD HH:mm:ss"),
                                type: status.isAlarm ? "Alarm" : "Info",
                                message: status.isAlarm ? [
                                    chooseAlarm(device, status.time, status.meter1Connected, status.meter2Connected, status.meter3Connected, "COLD", "Podłączenie ZW"),
                                    chooseAlarm(device, status.time, status.meter1Connected, status.meter2Connected, status.meter3Connected, "HOT", "Podłączenie CW"),
                                    chooseAlarm(device, status.time, status.meter1Disconnected, status.meter2Disconnected, status.meter3Disconnected, "COLD", "Odłączenie ZW"),
                                    chooseAlarm(device, status.time, status.meter1Disconnected, status.meter2Disconnected, status.meter3Disconnected, "HOT", "Odłączenie CW"),
                                    chooseAlarm(device, status.time, status.meter1DoseExceeded, status.meter2DoseExceeded, status.meter3DoseExceeded, "COLD", "Przekroczenie dawki ZW"),
                                    chooseAlarm(device, status.time, status.meter1DoseExceeded, status.meter2DoseExceeded, status.meter3DoseExceeded, "HOT", "Przekroczenie dawki CW"),
                                    chooseAlarm(device, status.time, status.meter1HoseCracked, status.meter2HoseCracked, status.meter3HoseCracked, "COLD", "Pęknięcie wężyka ZW"),
                                    chooseAlarm(device, status.time, status.meter1HoseCracked, status.meter2HoseCracked, status.meter3HoseCracked, "HOT", "Pęknięcie wężyka CW"),
                                    chooseAlarm(device, status.time, status.meter1Leak, status.meter2Leak, status.meter3Leak, "COLD", "Przeciek ZW"),
                                    chooseAlarm(device, status.time, status.meter1Leak, status.meter2Leak, status.meter3Leak, "HOT", "Przeciek CW"),
                                    chooseAlarm(device, status.time, status.meter1MinUsageNotReached, status.meter2MinUsageNotReached, status.meter3MinUsageNotReached, "COLD", "Minimalne zużycie ZW nieosiągnięte"),
                                    chooseAlarm(device, status.time, status.meter1MinUsageNotReached, status.meter2MinUsageNotReached, status.meter3MinUsageNotReached, "HOT", "Minimalne zużycie CW nieosiągnięte"),
                                    chooseAlarm(device, status.time, status.valve1Leak, status.valve2Leak, status.valve3Leak, "COLD", "Nieszczelność zaworu ZW"),
                                    chooseAlarm(device, status.time, status.valve1Leak, status.valve2Leak, status.valve3Leak, "HOT", "Nieszczelność zaworu CW"),
                                    chooseAlarm(device, status.time, status.valve1Opened, status.valve2Opened, status.valve3Opened, "COLD", "Otwarcie zaworu ZW"),
                                    chooseAlarm(device, status.time, status.valve1Opened, status.valve2Opened, status.valve3Opened, "HOT", "Otwarcie zaworu CW"),
                                    chooseAlarm(device, status.time, status.valve1Closed, status.valve2Closed, status.valve3Closed, "COLD", "Zamknięcie zaworu ZW"),
                                    chooseAlarm(device, status.time, status.valve1Closed, status.valve2Closed, status.valve3Closed, "HOT", "Zamknięcie zaworu CW"),
                                    status.batteryLow && status.batteryLow.time.getTime() === status.time.getTime() ? (status.batteryLow.value ? "Słaba bateria: " + status.batteryVoltage.value + "V" : null) : null,
                                ].filter(value => value).join(", ") : "Kalibracja: dzienna dawka ZW: " + chooseDailyDose(device, status.time, status.meter1DailyDose, status.meter2DailyDose, status.meter3DailyDose, "COLD") + " m3, natężenie przepływu ZW: " + chooseInterImpulseTime(device, status.time, status.meter1InterImpulseTime, status.meter2InterImpulseTime, status.meter3InterImpulseTime, "COLD") + " m3/h, dzienna dawka CW: " + chooseDailyDose(device, status.time, status.meter1DailyDose, status.meter2DailyDose, status.meter3DailyDose, "HOT") + " m3, natężenie przepływu CW: " + chooseInterImpulseTime(device, status.time, status.meter1InterImpulseTime, status.meter2InterImpulseTime, status.meter3InterImpulseTime, "HOT") + " m3/h",
                                gw: gwIdToName[status.rxMetadata.gwInfo[0].gwId] ? gwIdToName[status.rxMetadata.gwInfo[0].gwId] : status.rxMetadata.gwInfo[0].gwId,
                            }];
                        }))} initialOrderBy="time" initialOrder="desc"
                        className={classes.lastAlarmsTable} />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                    <RichTable name="Ostatnie odczyty" columns={
                        [
                            { id: 'time', numeric: false, disablePadding: false, label: 'Czas' },
                            { id: 'stateCold', numeric: true, disablePadding: false, label: 'Stan ZW (m3)' },
                            { id: 'stateHot', numeric: true, disablePadding: false, label: 'Stan CW (m3)' },
                            { id: 'stateHeat', numeric: true, disablePadding: false, label: 'Stan ciepła (GJ)' },
                            { id: 'gw', numeric: false, disablePadding: false, label: 'Brama' },
                        ]
                    } initialRowsById={new Map(deviceStatuses.filter(value => value.isReading).map((status, index) => {
                        const id = index;
                        return [id, {
                            time: moment(status.time).format("YYYY-MM-DD HH:mm:ss"),
                            stateCold: calculateState(device, status, "COLD").value,
                            stateHot: calculateState(device, status, "HOT").value,
                            stateHeat: calculateState(device, status, "HEAT").value,
                            gw: gwIdToName[status.rxMetadata.gwInfo[0].gwId] ? gwIdToName[status.rxMetadata.gwInfo[0].gwId] : status.rxMetadata.gwInfo[0].gwId
                        }];
                    }))} initialOrderBy="time" initialOrder="desc"
                        className={classes.lastReadingsTable} />
                </Grid>
            </Grid>
            {kiosk ? (<React.Fragment></React.Fragment>) :
                (<Grid container spacing={2}>
                    <Grid item xs={12} md={4} lg={4}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={12} lg={12}>
                                <Paper className={classes.chartPanel}>
                                    <TimedValueChart title="LoRa DR"
                                        label="wartość"
                                        color="black"
                                        entries={deviceStatuses.map(status => {
                                            return new TimedValue(status.time, loraDataRateToValue(status.rxMetadata.dataRate));
                                        }
                                        )} />
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={12} lg={12}>
                                <Paper className={classes.chartPanel}>
                                    <TimedValueChart title="RSSI"
                                        label="dBm"
                                        color="black"
                                        entries={deviceStatuses.map(status => {
                                            return new TimedValue(status.time, status.rxMetadata.gwInfo[0].rssi);
                                        }
                                        )} />
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={12} lg={12}>
                                <Paper className={classes.chartPanel}>
                                    <TimedValueChart title="SNR"
                                        label="dB"
                                        color="black"
                                        entries={deviceStatuses.map(status => {
                                            return new TimedValue(status.time, status.rxMetadata.gwInfo[0].snr);
                                        }
                                        )} />
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>)}
        </Container>
    );
}