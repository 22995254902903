import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from "@material-ui/core/TextField";
import Form from "./Form";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import InputAdornment from "@material-ui/core/InputAdornment";
import Checkbox from "@material-ui/core/Checkbox";
import * as moment from "moment";
import MomentUtils from '@date-io/moment';
import {
    MuiPickersUtilsProvider,
    TimePicker
} from '@material-ui/pickers';
import { MenuItem, Select } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    formControl: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    header: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    label: {
        fontSize: "1rem"
    },
}));

export default function DeviceForm({ edit, initialDevice, onSubmit, admin }) {
    const classes = useStyles();

    const [device, setDevice] = React.useState(initialDevice);

    function onFormSubmit(event) {
        device.notificationMsisdns = device.notificationMsisdns.filter(str => str !== "");
        device.notificationEmailAddresses = device.notificationEmailAddresses.filter(str => str !== "");
        onSubmit(device);
        event.preventDefault();
    }

    function onChange(updateState) {
        setDevice(prevState => {
            const prevStateCloned = Object.assign({}, prevState);
            return updateState(prevStateCloned);
        });
    }

    return <Form title="Urządzenie" onSubmit={onFormSubmit} submitButtonLabel={edit ? "Zapisz" : "Dodaj"}>
        <FormControl className={classes.formControl}>
            <FormLabel className={classes.label}>Typ</FormLabel>
            <Select
                labelId="type"
                value={device.type}
                onChange={event => {
                    const value = event.target.value;
                    onChange(device => {
                        device.type = value;
                        return device;
                    });
                }}
                disabled={!admin}
            >
                <MenuItem value="FILA">Fila</MenuItem>
                <MenuItem value="ADEUNIS_PULSE_4">Adeunis Pulse 4</MenuItem>
            </Select>
        </FormControl>
        <TextField
            name="name"
            label="Nazwa"
            margin="normal"
            value={device.name}
            onChange={event => {
                const value = event.target.value;
                onChange(device => {
                    device.name = value;
                    return device;
                });
            }}
            inputProps={{
                pattern: "[a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ0-9 -_]+",
            }}
            fullWidth
            required
            helperText={"Dozwolone są tylko znaki alfanumeryczne, spacja, \"-\", \"-\" lub \".\""}
            disabled={!admin}
        />
        <TextField
            name="description"
            label="Opis"
            margin="normal"
            value={device.description ? device.description : ""}
            onChange={event => {
                const value = event.target.value !== "" ? event.target.value : null;
                onChange(device => {
                    device.description = value;
                    return device;
                });
            }}
            inputProps={{
                pattern: "[^\\n]+",
            }}
            fullWidth
        />
        <Typography variant="button" display="block" className={classes.header}>
            Powiadomienia
        </Typography>
        <TextField
            name="notificationMsisdns"
            label="Numery telefonów"
            margin="normal"
            value={device.notificationMsisdns.join(",")}
            onChange={event => {
                const value = event.target.value.split(",").map(str => str.trim());
                onChange(device => {
                    device.notificationMsisdns = value;
                    return device;
                });
            }}
            inputProps={{
                pattern: "^(\\+(?:[0-9] ?){6,14}[0-9])(, ?\\+(?:[0-9] ?){6,14}[0-9])*$",
            }}
            fullWidth
            helperText={"Numery telefonów w międzynarodowym formacie MSISDN (z prefiksem \"+\" i kodem kraju), przedzielone przecinkami"}
        />
        <TextField
            name="notificationEmailAddresses"
            label="Adresy e-mail"
            margin="normal"
            value={device.notificationEmailAddresses.join(",")}
            onChange={event => {
                const value = event.target.value.split(",").map(str => str.trim());
                onChange(device => {
                    device.notificationEmailAddresses = value;
                    return device;
                });
            }}
            inputProps={{
                pattern: "^((([^<>()\\[\\]\\\\.,;:\\s@\"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@\"]+)*)|(\".+\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,})))(, ?(([^<>()\\[\\]\\\\.,;:\\s@\"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@\"]+)*)|(\".+\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,})))*$",
            }}
            fullWidth
            helperText={"Adres e-mail w formacie zgodnym z RFC 5322, przedzielone przecinkami"}
        />
        <Typography variant="button" display="block" className={classes.header}>
            Inkasent
        </Typography>
        <TextField
            name="collectorId"
            label="ID"
            margin="normal"
            value={device.collectorId ? device.collectorId : ""}
            onChange={event => {
                const value = event.target.value !== "" ? event.target.value : null;
                onChange(device => {
                    device.collectorId = value;
                    return device;
                });
            }}
            fullWidth
        />
        <Typography variant="button" display="block" className={classes.header}>
            Klient
        </Typography>
        <TextField
            name="clientId"
            label="ID"
            margin="normal"
            value={device.clientId ? device.clientId : ""}
            onChange={event => {
                const value = event.target.value !== "" ? event.target.value : null;
                onChange(device => {
                    device.clientId = value;
                    return device;
                });
            }}
            fullWidth
        />
        <TextField
            name="clientAddress"
            label="Adres"
            margin="normal"
            value={device.clientAddress ? device.clientAddress : ""}
            onChange={event => {
                const value = event.target.value !== "" ? event.target.value : null;
                onChange(device => {
                    device.clientAddress = value;
                    return device;
                });
            }}
            fullWidth
        />
        <Grid container spacing={3}>
            <Grid item xs={12} md={4} lg={4}>
                <Typography variant="button" display="block" className={classes.header}>
                    Licznik 1
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12} lg={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="meter1Active"
                                    checked={device.meter1Active}
                                    onChange={event => {
                                        const value = event.target.checked;
                                        onChange(device => {
                                            device.meter1Active = value;
                                            return device;
                                        });
                                    }}
                                    color="default"
                                />
                            }
                            label="Aktywny"
                        />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                        <TextField
                            name="meter1Id"
                            label="ID"
                            margin="normal"
                            value={device.meter1Id ? device.meter1Id : ""}
                            onChange={event => {
                                const value = event.target.value !== "" ? event.target.value : null;
                                onChange(device => {
                                    device.meter1Id = value;
                                    return device;
                                });
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                        <TextField
                            name="meter1Sn"
                            label="SN"
                            margin="normal"
                            value={device.meter1Sn ? device.meter1Sn : ""}
                            onChange={event => {
                                const value = event.target.value !== "" ? event.target.value : null;
                                onChange(device => {
                                    device.meter1Sn = value;
                                    return device;
                                });
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                        <TextField
                            name="meter1InitialIndex"
                            label="Zewn. stan początkowy"
                            margin="normal"
                            type="number"
                            inputProps={{
                                step: "0.001"
                            }}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">m3/GJ</InputAdornment>,
                            }}
                            value={device.meter1InitialIndex / 1000}
                            onChange={event => {
                                const value = Math.trunc(event.target.value * 1000);
                                onChange(device => {
                                    device.meter1InitialIndex = value;
                                    return device;
                                });
                            }}
                            fullWidth
                            helperText="Wartość na cyferblacie licznika w momencie instalacji"
                            required
                        />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                        <TextField
                            name="meter1InternalInitialIndex"
                            label="Wewn. stan początkowy"
                            margin="normal"
                            type="number"
                            inputProps={{
                                step: "0.001"
                            }}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">m3/GJ</InputAdornment>,
                            }}
                            value={device.meter1InternalInitialIndex / 1000}
                            onChange={event => {
                                const value = Math.trunc(event.target.value * 1000);
                                onChange(device => {
                                    device.meter1InternalInitialIndex = value;
                                    return device;
                                });
                            }}
                            fullWidth
                            helperText="Wartość w pamięci urządzenia w momencie instalacji"
                            required
                        />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                        <TextField
                            name="meter1Multiplier"
                            label="Mnożnik"
                            margin="normal"
                            type="number"
                            inputProps={{
                                step: "1"
                            }}
                            value={device.meter1Multiplier ? device.meter1Multiplier : 1}
                            onChange={event => {
                                const value = event.target.value;
                                onChange(device => {
                                    device.meter1Multiplier = value;
                                    return device;
                                });
                            }}
                            fullWidth
                            required
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend" className={classes.label}>Typ</FormLabel>
                            <RadioGroup name="meter1WaterType"
                                value={device.meter1WaterType ? device.meter1WaterType : "COLD"}
                                onChange={event => {
                                    const value = event.target.value;
                                    onChange(device => {
                                        device.meter1WaterType = value;
                                        return device;
                                    });
                                }}
                            >
                                <FormControlLabel value="COLD" control={<Radio color="default" />} label="ZW" />
                                <FormControlLabel value="HOT" control={<Radio color="default" />} label="CW" />
                                <FormControlLabel value="HEAT" control={<Radio color="default" />} label="Ciepło" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
                <Typography variant="button" display="block" className={classes.header}>
                    Licznik 2
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12} lg={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="meter2Active"
                                    checked={device.meter2Active}
                                    onChange={event => {
                                        const value = event.target.checked;
                                        onChange(device => {
                                            device.meter2Active = value;
                                            return device;
                                        });
                                    }}
                                    color="default"
                                />
                            }
                            label="Aktywny"
                        />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                        <TextField
                            name="meter2Id"
                            label="ID"
                            margin="normal"
                            value={device.meter2Id ? device.meter2Id : ""}
                            onChange={event => {
                                const value = event.target.value !== "" ? event.target.value : null;
                                onChange(device => {
                                    device.meter2Id = value;
                                    return device;
                                });
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                        <TextField
                            name="meter2Sn"
                            label="SN"
                            margin="normal"
                            value={device.meter2Sn ? device.meter2Sn : ""}
                            onChange={event => {
                                const value = event.target.value !== "" ? event.target.value : null;
                                onChange(device => {
                                    device.meter2Sn = value;
                                    return device;
                                });
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                        <TextField
                            name="meter2InitialIndex"
                            label="Zewn. stan początkowy"
                            margin="normal"
                            type="number"
                            inputProps={{
                                step: "0.001"
                            }}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">m3/GJ</InputAdornment>,
                            }}
                            value={device.meter2InitialIndex / 1000}
                            onChange={event => {
                                const value = Math.trunc(event.target.value * 1000);
                                onChange(device => {
                                    device.meter2InitialIndex = value;
                                    return device;
                                });
                            }}
                            fullWidth
                            helperText="Wartość na cyferblacie licznika w momencie instalacji"
                            required
                        />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                        <TextField
                            name="meter2InternalInitialIndex"
                            label="Wewn. stan początkowy"
                            margin="normal"
                            type="number"
                            inputProps={{
                                step: "0.001"
                            }}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">m3/GJ</InputAdornment>,
                            }}
                            value={device.meter2InternalInitialIndex / 1000}
                            onChange={event => {
                                const value = Math.trunc(event.target.value * 1000);
                                onChange(device => {
                                    device.meter2InternalInitialIndex = value;
                                    return device;
                                });
                            }}
                            fullWidth
                            helperText="Wartość w pamięci urządzenia w momencie instalacji"
                            required
                        />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                        <TextField
                            name="meter2Multiplier"
                            label="Mnożnik"
                            margin="normal"
                            type="number"
                            inputProps={{
                                step: "1"
                            }}
                            value={device.meter2Multiplier ? device.meter2Multiplier : 1}
                            onChange={event => {
                                const value = event.target.value;
                                onChange(device => {
                                    device.meter2Multiplier = value;
                                    return device;
                                });
                            }}
                            fullWidth
                            required
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend" className={classes.label}>Typ</FormLabel>
                            <RadioGroup name="meter2WaterType"
                                value={device.meter2WaterType ? device.meter2WaterType : "HOT"}
                                onChange={event => {
                                    const value = event.target.value;
                                    onChange(device => {
                                        device.meter2WaterType = value;
                                        return device;
                                    });
                                }}
                            >
                                <FormControlLabel value="COLD" control={<Radio color="default" />} label="ZW" />
                                <FormControlLabel value="HOT" control={<Radio color="default" />} label="CW" />
                                <FormControlLabel value="HEAT" control={<Radio color="default" />} label="Ciepło" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
                <Typography variant="button" display="block" className={classes.header}>
                    Licznik 3
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12} lg={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="meter3Active"
                                    checked={device.meter3Active}
                                    onChange={event => {
                                        const value = event.target.checked;
                                        onChange(device => {
                                            device.meter3Active = value;
                                            return device;
                                        });
                                    }}
                                    color="default"
                                />
                            }
                            label="Aktywny"
                        />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                        <TextField
                            name="meter3Id"
                            label="ID"
                            margin="normal"
                            value={device.meter3Id ? device.meter3Id : ""}
                            onChange={event => {
                                const value = event.target.value !== "" ? event.target.value : null;
                                onChange(device => {
                                    device.meter3Id = value;
                                    return device;
                                });
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                        <TextField
                            name="meter3Sn"
                            label="SN"
                            margin="normal"
                            value={device.meter3Sn ? device.meter3Sn : ""}
                            onChange={event => {
                                const value = event.target.value !== "" ? event.target.value : null;
                                onChange(device => {
                                    device.meter3Sn = value;
                                    return device;
                                });
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                        <TextField
                            name="meter3InitialIndex"
                            label="Zewn. stan początkowy"
                            margin="normal"
                            type="number"
                            inputProps={{
                                step: "0.001"
                            }}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">m3/GJ</InputAdornment>,
                            }}
                            value={device.meter3InitialIndex / 1000}
                            onChange={event => {
                                const value = Math.trunc(event.target.value * 1000);
                                onChange(device => {
                                    device.meter3InitialIndex = value;
                                    return device;
                                });
                            }}
                            fullWidth
                            helperText="Wartość na cyferblacie licznika w momencie instalacji"
                            required
                        />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                        <TextField
                            name="meter3InternalInitialIndex"
                            label="Wewn. stan początkowy"
                            margin="normal"
                            type="number"
                            inputProps={{
                                step: "0.001"
                            }}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">m3/GJ</InputAdornment>,
                            }}
                            value={device.meter3InternalInitialIndex / 1000}
                            onChange={event => {
                                const value = Math.trunc(event.target.value * 1000);
                                onChange(device => {
                                    device.meter3InternalInitialIndex = value;
                                    return device;
                                });
                            }}
                            fullWidth
                            helperText="Wartość w pamięci urządzenia w momencie instalacji"
                            required
                        />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                        <TextField
                            name="meter3Multiplier"
                            label="Mnożnik"
                            margin="normal"
                            type="number"
                            inputProps={{
                                step: "1"
                            }}
                            value={device.meter3Multiplier ? device.meter3Multiplier : 1}
                            onChange={event => {
                                const value = event.target.value;
                                onChange(device => {
                                    device.meter3Multiplier = value;
                                    return device;
                                });
                            }}
                            fullWidth
                            required
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend" className={classes.label}>Typ</FormLabel>
                            <RadioGroup name="meter3WaterType"
                                value={device.meter3WaterType ? device.meter3WaterType : "HEAT"}
                                onChange={event => {
                                    const value = event.target.value;
                                    onChange(device => {
                                        device.meter3WaterType = value;
                                        return device;
                                    });
                                }}
                            >
                                <FormControlLabel value="COLD" control={<Radio color="default" />} label="ZW" />
                                <FormControlLabel value="HOT" control={<Radio color="default" />} label="CW" />
                                <FormControlLabel value="HEAT" control={<Radio color="default" />} label="Ciepło" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        <Grid container spacing={3}>
            <Grid item xs={12} md={4} lg={4}>
                <Typography variant="button" display="block" className={classes.header}>
                    Zawór 1
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12} lg={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="valve1Active"
                                    checked={device.valve1Active}
                                    onChange={event => {
                                        const value = event.target.checked;
                                        onChange(device => {
                                            device.valve1Active = value;
                                            return device;
                                        });
                                    }}
                                    color="default"
                                    disabled={!admin}
                                />
                            }
                            label="Aktywny"
                        />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend" className={classes.label}>Stan</FormLabel>
                            <RadioGroup name="valve1Open"
                                value={String(device.valve1Open ? device.valve1Open : true)}
                                onChange={event => {
                                    const value = event.target.value === "true";
                                    onChange(device => {
                                        device.valve1Open = value;
                                        return device;
                                    });
                                }}
                            >
                                <FormControlLabel value="true" control={<Radio color="default" />} label="Otwarty" />
                                <FormControlLabel value="false" control={<Radio color="default" />}
                                    label="Zamknięty" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
                <Typography variant="button" display="block" className={classes.header}>
                    Zawór 2
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12} lg={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="valve2Active"
                                    checked={device.valve2Active}
                                    onChange={event => {
                                        const value = event.target.checked;
                                        onChange(device => {
                                            device.valve2Active = value;
                                            return device;
                                        });
                                    }}
                                    color="default"
                                    disabled={!admin}
                                />
                            }
                            label="Aktywny"
                        />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend" className={classes.label}>Stan</FormLabel>
                            <RadioGroup name="valve2Open"
                                value={String(device.valve2Open ? device.valve2Open : true)}
                                onChange={event => {
                                    const value = event.target.value === "true";
                                    onChange(device => {
                                        device.valve2Open = value;
                                        return device;
                                    });
                                }}
                            >
                                <FormControlLabel value="true" control={<Radio color="default" />} label="Otwarty" />
                                <FormControlLabel value="false" control={<Radio color="default" />}
                                    label="Zamknięty" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>

            </Grid>
            <Grid item xs={12} md={4} lg={4}>
                <Typography variant="button" display="block" className={classes.header}>
                    Zawór 3
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12} lg={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="valve3Active"
                                    checked={device.valve2Active}
                                    onChange={event => {
                                        const value = event.target.checked;
                                        onChange(device => {
                                            device.valve3Active = value;
                                            return device;
                                        });
                                    }}
                                    color="default"
                                    disabled={!admin}
                                />
                            }
                            label="Aktywny"
                        />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend" className={classes.label}>Stan</FormLabel>
                            <RadioGroup name="valve3Open"
                                value={String(device.valve3Open ? device.valve3Open : true)}
                                onChange={event => {
                                    const value = event.target.value === "true";
                                    onChange(device => {
                                        device.valve3Open = value;
                                        return device;
                                    });
                                }}
                            >
                                <FormControlLabel value="true" control={<Radio color="default" />} label="Otwarty" />
                                <FormControlLabel value="false" control={<Radio color="default" />}
                                    label="Zamknięty" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        <Typography variant="button" display="block" className={classes.header}>
            Kalibracja
        </Typography>
        <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={6}>
                <TextField
                    name="hoseCrackCoefficient"
                    label="Współczyn. pęknięcia węż."
                    type="number"
                    margin="normal"
                    value={device.hoseCrackCoefficient ? 1 / device.hoseCrackCoefficient : ""}
                    onChange={event => {
                        const value = event.target.value !== "" ? 1 / event.target.value : null;
                        onChange(device => {
                            device.hoseCrackCoefficient = value;
                            return device;
                        });
                    }}
                    fullWidth
                    disabled={!admin}
                />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
                <TextField
                    name="interImpulseTime"
                    label="Natężenie przepływu"
                    type="number"
                    margin="normal"
                    value={device.interImpulseTime ? device.interImpulseTime.asSeconds() !== 0 ? 36 / device.interImpulseTime.asSeconds() : "0" : ""}
                    onChange={event => {
                        const value = event.target.value !== "" ? event.target.value === "0" ? moment.duration("PT0S") : moment.duration("PT" + 36 / parseInt(event.target.value, 10) + "S") : null;
                        onChange(device => {
                            device.interImpulseTime = value;
                            return device;
                        });
                    }}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">m3/h</InputAdornment>,
                    }}
                    fullWidth
                    disabled={!admin}
                />
            </Grid>
        </Grid>
        <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={6}>
                <TextField
                    name="dailyDoseCoefficient"
                    label="Współczyn. dawki dziennej"
                    type="number"
                    margin="normal"
                    value={device.dailyDoseCoefficient ? device.dailyDoseCoefficient : ""}
                    onChange={event => {
                        const value = event.target.value !== "" ? event.target.value : null;
                        onChange(device => {
                            device.dailyDoseCoefficient = value;
                            return device;
                        });
                    }}
                    fullWidth
                    disabled={!admin}
                />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
                <TextField
                    name="dailyDose"
                    label="Dawka dzienna"
                    type="number"
                    margin="normal"
                    value={device.dailyDose ? device.dailyDose : ""}
                    onChange={event => {
                        const value = event.target.value !== "" ? event.target.value : null;
                        onChange(device => {
                            device.dailyDose = value;
                            return device;
                        });
                    }}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">l</InputAdornment>,
                    }}
                    fullWidth
                    disabled={!admin}
                />
            </Grid>
        </Grid>
        <Typography variant="button" display="block" className={classes.header}>
            Pomiary
        </Typography>
        <TextField
            name="measurementInterval"
            label="Interwał"
            type="number"
            margin="normal"
            inputProps={{
                step: "1"
            }}
            value={device.measurementInterval.asMinutes()}
            onChange={event => {
                const value = moment.duration("PT" + event.target.value + "M");
                onChange(device => {
                    device.measurementInterval = value;
                    return device;
                });
            }}
            InputProps={{
                endAdornment: <InputAdornment position="end">min</InputAdornment>,
            }}
            fullWidth
            required
            disabled={!admin}
        />
        <Typography variant="button" display="block" className={classes.header}>
            Nadawanie
        </Typography>
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={6}>
                    <TimePicker
                        name="txTimeRange.from"
                        value={new Date("1970-01-01T" + device.txTimeRange.from)}
                        onChange={value => {
                            setDevice(prevState => {
                                const updateState = Object.assign({}, prevState);
                                updateState.txTimeRange.from = value.format("HH:mm:ss");
                                return updateState;
                            });
                        }}
                        views={["hours"]}
                        label="Od"
                        margin="normal"
                        ampm={false}
                        invalidDateMessage={"Nieprawidłowy format"}
                        cancelLabel="Anuluj"
                        okLabel="Ustaw"
                        format="HH:mm"
                        disabled={!admin}
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                    <TimePicker
                        name="txTimeRange.to"
                        value={new Date("1970-01-01T" + device.txTimeRange.to)}
                        onChange={value => {
                            setDevice(prevState => {
                                const updateState = Object.assign({}, prevState);
                                updateState.txTimeRange.to = value.format("HH:mm:ss");
                                return updateState;
                            });
                        }}
                        views={["hours"]}
                        label="Do"
                        margin="normal"
                        ampm={false}
                        invalidDateMessage={"Nieprawidłowy format"}
                        cancelLabel="Anuluj"
                        okLabel="Ustaw"
                        format="HH:mm"
                        disabled={!admin}
                    />
                </Grid>
            </Grid>
        </MuiPickersUtilsProvider>
        <TextField
            name="maxTxDelay"
            label="Maksymalne opóźnienie"
            type="number"
            margin="normal"
            inputProps={{
                step: "1"
            }}
            value={device.maxTxDelay ? device.maxTxDelay.asMinutes() : ""}
            onChange={event => {
                const value = event.target.value !== "" ? moment.duration("PT" + event.target.value + "M") : null;
                onChange(device => {
                    device.maxTxDelay = value;
                    return device;
                });
            }}
            InputProps={{
                endAdornment: <InputAdornment position="end">min</InputAdornment>,
            }}
            fullWidth
            disabled={!admin}
        />
        <Typography variant="button" display="block" className={classes.header}>
            LoRaWAN
        </Typography>
        <TextField
            name="lorawanDevEui"
            label="EUI"
            margin="normal"
            value={device.lorawanDevEui}
            onChange={event => {
                const value = event.target.value;
                onChange(device => {
                    device.id = "eui64-" + value;
                    device.lorawanDevEui = value;
                    device.name = device.lorawanDevEui;
                    return device;
                });
            }}
            inputProps={{
                pattern: "[A-Fa-f0-9]{16}"
            }}
            fullWidth
            required
            disabled={edit || !admin}
        />
        <TextField
            name="lorawanAppKey"
            label="Klucz aplikacyjny"
            margin="normal"
            type="password"
            value={device.lorawanAppKey}
            onChange={event => {
                const value = event.target.value;
                onChange(device => {
                    device.lorawanAppKey = value;
                    device.lorawanNwkKey = device.lorawanAppKey;
                    return device;
                });
            }}
            inputProps={{
                pattern: "[A-Fa-f0-9]{32}"
            }}
            fullWidth
            required
            disabled={edit || !admin}
        />
    </Form>;
}