import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Form from "./Form";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
    formControl: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    header: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    label: {
        fontSize: "1rem"
    },
}));

export default function GroupDeviceAttributesForm({ edit, initialGroupDeviceAttributes, onSubmit }) {
    const classes = useStyles();

    const [groupDeviceAttributes, setGroupDeviceAttributes] = React.useState(initialGroupDeviceAttributes);

    function onFormSubmit(event) {
        onSubmit(groupDeviceAttributes);
        event.preventDefault();
    }

    function onChange(updateState) {
        setGroupDeviceAttributes(prevState => {
            const prevStateCloned = Object.assign({}, prevState);
            return updateState(prevStateCloned);
        });
    }

    return <Form title="Instalacja" onSubmit={onFormSubmit} submitButtonLabel={edit ? "Zapisz" : "Dodaj"}>
        <Typography variant="button" display="block" className={classes.header}>
            Bilans
        </Typography>
        <Grid container spacing={3}>
            <Grid item xs={12} md={4} lg={4}>
                <Typography variant="button" display="block" className={classes.header}>
                    Licznik 1
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12} lg={12}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend" className={classes.label}>Rola</FormLabel>
                            <RadioGroup name="meter1BalanceRole"
                                value={groupDeviceAttributes.meter1BalanceRole}
                                onChange={event => {
                                    const value = event.target.value;
                                    onChange(groupDevice => {
                                        groupDevice.meter1BalanceRole = value;
                                        return groupDevice;
                                    });
                                }}>
                                <FormControlLabel value={"MAIN"} control={<Radio color="default" />} label="Główny" />
                                <FormControlLabel value={"SUBMETER"} control={<Radio color="default" />}
                                    label="Podlicznik" />
                                <FormControlLabel value={"NONE"} control={<Radio color="default" />}
                                    label="Brak" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend" className={classes.label}>Typ</FormLabel>
                            <RadioGroup name="meter1BalanceWaterType"
                                value={groupDeviceAttributes.meter1BalanceWaterType}
                                onChange={event => {
                                    const value = event.target.value;
                                    onChange(groupDevice => {
                                        groupDevice.meter1BalanceWaterType = value;
                                        return groupDevice;
                                    });
                                }}>
                                <FormControlLabel value={"COLD"} control={<Radio color="default" />} label="ZW" />
                                <FormControlLabel value={"HOT"} control={<Radio color="default" />}
                                    label="CW" />
                                <FormControlLabel value={"HEAT"} control={<Radio color="default" />}
                                    label="Ciepło" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
                <Typography variant="button" display="block" className={classes.header}>
                    Licznik 2
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12} lg={12}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend" className={classes.label}>Rola</FormLabel>
                            <RadioGroup name="meter2BalanceRole"
                                value={groupDeviceAttributes.meter2BalanceRole}
                                onChange={event => {
                                    const value = event.target.value;
                                    onChange(groupDevice => {
                                        groupDevice.meter2BalanceRole = value;
                                        return groupDevice;
                                    });
                                }}>
                                <FormControlLabel value={"MAIN"} control={<Radio color="default" />} label="Główny" />
                                <FormControlLabel value={"SUBMETER"} control={<Radio color="default" />}
                                    label="Podlicznik" />
                                <FormControlLabel value={"NONE"} control={<Radio color="default" />}
                                    label="Brak" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend" className={classes.label}>Typ</FormLabel>
                            <RadioGroup name="meter2BalanceWaterType"
                                value={groupDeviceAttributes.meter2BalanceWaterType}
                                onChange={event => {
                                    const value = event.target.value;
                                    onChange(groupDevice => {
                                        groupDevice.meter2BalanceWaterType = value;
                                        return groupDevice;
                                    });
                                }}>
                                <FormControlLabel value={"COLD"} control={<Radio color="default" />} label="ZW" />
                                <FormControlLabel value={"HOT"} control={<Radio color="default" />}
                                    label="CW" />
                                <FormControlLabel value={"HEAT"} control={<Radio color="default" />}
                                    label="Ciepło" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
                <Typography variant="button" display="block" className={classes.header}>
                    Licznik 3
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12} lg={12}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend" className={classes.label}>Rola</FormLabel>
                            <RadioGroup name="meter3BalanceRole"
                                value={groupDeviceAttributes.meter3BalanceRole}
                                onChange={event => {
                                    const value = event.target.value;
                                    onChange(groupDevice => {
                                        groupDevice.meter3BalanceRole = value;
                                        return groupDevice;
                                    });
                                }}>
                                <FormControlLabel value={"MAIN"} control={<Radio color="default" />} label="Główny" />
                                <FormControlLabel value={"SUBMETER"} control={<Radio color="default" />}
                                    label="Podlicznik" />
                                <FormControlLabel value={"NONE"} control={<Radio color="default" />}
                                    label="Brak" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend" className={classes.label}>Typ</FormLabel>
                            <RadioGroup name="meter3BalanceWaterType"
                                value={groupDeviceAttributes.meter3BalanceWaterType}
                                onChange={event => {
                                    const value = event.target.value;
                                    onChange(groupDevice => {
                                        groupDevice.meter3BalanceWaterType = value;
                                        return groupDevice;
                                    });
                                }}>
                                <FormControlLabel value={"COLD"} control={<Radio color="default" />} label="ZW" />
                                <FormControlLabel value={"HOT"} control={<Radio color="default" />}
                                    label="CW" />
                                <FormControlLabel value={"HEAT"} control={<Radio color="default" />}
                                    label="Ciepło" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </Form>;
}