export default function GroupDeviceAttributes(
    groupId,
    deviceId,
    meter1BalanceRole,
    meter2BalanceRole,
    meter3BalanceRole,
    meter1BalanceWaterType,
    meter2BalanceWaterType,
    meter3BalanceWaterType) {
    this.groupId = groupId;
    this.deviceId = deviceId;
    this.meter1BalanceRole = meter1BalanceRole;
    this.meter2BalanceRole = meter2BalanceRole;
    this.meter3BalanceRole = meter3BalanceRole;
    this.meter1BalanceWaterType = meter1BalanceWaterType;
    this.meter2BalanceWaterType = meter2BalanceWaterType;
    this.meter3BalanceWaterType = meter3BalanceWaterType;

    this.toJson = function () {
        return {
            groupId: this.groupId,
            deviceId: this.deviceId,
            meter1BalanceRole: this.meter1BalanceRole,
            meter2BalanceRole: this.meter2BalanceRole,
            meter3BalanceRole: this.meter3BalanceRole,
            meter1BalanceWaterType: this.meter1BalanceWaterType,
            meter2BalanceWaterType: this.meter2BalanceWaterType,
            meter3BalanceWaterType: this.meter3BalanceWaterType
        };
    }
}

GroupDeviceAttributes.fromJson = function (json) {
    return new GroupDeviceAttributes(
        json.groupId,
        json.deviceId,
        json.meter1BalanceRole,
        json.meter2BalanceRole,
        json.meter3BalanceRole,
        json.meter1BalanceWaterType,
        json.meter2BalanceWaterType,
        json.meter3BalanceWaterType);
};